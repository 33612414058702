import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import { AiOutlineMenu } from "react-icons/ai"
import { AiOutlineClose } from "react-icons/ai"
import useOnClickOutside from "use-onclickoutside"
import SideMenu from "../Sidemenu/SideMenu"

const HamburgerNav = ({ activeSlug }: { activeSlug?: string }) => {
  const [drawer, setDrawer] = useState(false)
  const ref = React.useRef(null)
  useOnClickOutside(ref, () => {
    setDrawer(false)
  })

  return (
    <div>
      <div
        className={`btn btn-primary  ml-4 md:hidden`}
        onClick={() => {
          setDrawer(true)
        }}
      >
        <AiOutlineMenu />
      </div>
      <AnimatePresence>
        {drawer && (
          <>
            <motion.div
              className="pointer-events-none fixed inset-0 h-screen w-screen bg-black bg-opacity-40 "
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            ></motion.div>
            <motion.div
              className="w-screenp-10 fixed inset-0 z-30 h-screen pt-32"
              initial={{ x: "100%" }}
              transition={{ type: "spring", bounce: 0, duration: 0.4 }}
              animate={{
                x: 0,
              }}
              exit={{
                x: "100%",
              }}
            >
              <div
                ref={ref}
                className="fixed right-0  top-0 h-screen w-[80vw] overflow-y-auto  bg-base-100 px-7  pb-8  shadow-xl "
              >
                <div className="mr-3 flex w-full justify-end pt-4 pb-8">
                  <div
                    className="btn btn-error "
                    onClick={() => {
                      setDrawer(false)
                    }}
                  >
                    <AiOutlineClose />
                  </div>
                </div>
                <SideMenu activeSlug={activeSlug} />
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  )
}

export default HamburgerNav
