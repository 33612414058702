import React from "react"
import { Link } from "gatsby"
import { NavigationTreeItem } from "../../../interfaces/Navigations"
import { FiExternalLink } from "react-icons/fi"
const MenuItem = ({
  item,
  activeSlug,
  className,
}: {
  item: NavigationTreeItem
  activeSlug?: string
  className?: string
}) => {
  const itemPath = item?.related?.slug ? `/${item.related.slug}` : item.path
  const itemSlug = item?.related?.slug ? item.related.slug : item.path

  return (
    <li className="w-full text-secondary-content">
      {item.type !== "EXTERNAL" ? (
        <Link
          className={`flex w-full items-center py-4 px-6 ${className} ${
            itemSlug === activeSlug ? "active" : ""
          }`}
          to={itemPath}
        >
          {itemSlug === activeSlug && (
            <span className="mr-2 block h-1 w-4 border-b-2 border-primary-content"></span>
          )}
          {item.title}
        </Link>
      ) : (
        <a
          className=" block w-full py-4 px-6 "
          href={item.path}
          target="_blank"
        >
          <FiExternalLink />
          {item.title}
        </a>
      )}
    </li>
  )
}

export default MenuItem
