import React from "react"
import { NavigationTreeItem } from "../../interfaces/Navigations"
import Footer from "../Layout/footer/Footer"
import HamburgerNav from "../Layout/HamburgerNav/HamburgerNav"
import HeaderLinks from "../Layout/Header/HeaderLinks"
import ImageBar from "../Layout/ImageBar"
import FloatSearchBar from "../Layout/SearchBar/FloatSearchBar"
import SideMenu from "../Layout/Sidemenu/SideMenu"
import Logo from "../Logo/Logo"

const SideLayout = ({
  children,
  activeItem,
  activeSlug,
}: {
  children: React.ReactNode
  activeItem?: NavigationTreeItem
  activeSlug?: string
}) => {
  return (
    <>
      <div className="dotted-bg grid-cols-8 md:grid ">
        <div className=" fixed top-0 left-0 right-0 z-30 col-span-2 bg-gradient-to-br from-primary to-secondary md:static md:bg-gradient-to-b">
          <div className="h-full md:min-h-screen ">
            <div className="top-0 flex flex-col justify-between md:sticky md:h-screen">
              <div className="flex items-center justify-between gap-5 p-5 md:h-[20vh] xl:p-10">
                <Logo />
                <div className="flex gap-2 md:hidden">
                  <FloatSearchBar />
                  <HamburgerNav activeSlug={activeSlug} />
                </div>
              </div>
              <div className="ml-8 mb-8 hidden h-[80vh] overflow-y-auto pb-6  scrollbar-thin  scrollbar-thumb-stone-700 md:block md:h-auto">
                <SideMenu activeSlug={activeSlug} key="sideMenuforSidePanel" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-6 px-5 md:px-14">
          <div className="mt-5 mb-10 flex w-full justify-end gap-5">
            <HeaderLinks />
            <div className="hidden md:block">
              <FloatSearchBar />
            </div>
          </div>
          <div className="my-8 mt-32 min-h-[75vh] md:mt-0">{children}</div>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default SideLayout
