import React from "react"
import SideLayout from "../Styles/SideLayout"
import { Helmet } from "react-helmet"
import { ActivePage } from "../../interfaces/Page"
import useSiteMetadata from "../../graphql/useSiteMetadata"
import TopNavigationLayout from "../Styles/TopNavigationLayout"

export const Layout = ({
  children,
  activePage,
}: {
  children: React.ReactNode
  activePage: ActivePage
}) => {
  const { title } = useSiteMetadata()
  return (
    <>
      <Helmet defaultTitle={title} titleTemplate={`%s - ${title}`}>
        <title>{activePage?.title}</title>
      </Helmet>
      <SideLayout activeSlug={activePage.slug}>{children}</SideLayout>
    </>
  )
}
