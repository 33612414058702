import { Link } from "gatsby"
import React from "react"

const Logo = () => {
  return (
    <div>
      <Link to="/" className="flex items-center gap-2 xl:gap-5">
        {" "}
        <div className="w-10 md:w-20 ">
          <svg
            viewBox="0 0 358 351"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.95752 144.015L179.132 0.13623L357.307 144.015V350.547H272.669V289.352C272.669 237.693 230.791 195.815 179.132 195.815C127.473 195.815 85.5949 237.693 85.5949 289.352V350.547H0.95752V144.015Z"
              fill="url(#paint0_linear_412_195)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_412_195"
                x1="179.132"
                y1="0.13623"
                x2="179.132"
                y2="350.547"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF6665" />
                <stop offset="1" stop-color="#F5A604" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <span className="ml-4 py-2 text-xl font-bold md:text-2xl xl:text-4xl">
          Základní Škola Anafra
        </span>
      </Link>
    </div>
  )
}

export default Logo
